






































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import Pagination from "@/components/pagination/Pagination.vue";
import { CouponModule } from "@/store/modules/coupon";
import { IPanelData } from "@/data/commonModel";
import { IStoreModel } from "@/pages/store/data/storeModel";
import { getAllStores } from "@/remote/store";
import { ICouponModel, ICouponListQuery } from "../data/couponModel";
import { getCoupon, deleteCoupon, publish } from "@/remote/coupon";
import { getWineryInfo } from "@/remote/common";

@Component({
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop({
    required: true,
  })
  panelObj!: IPanelData;

  columnLableWidth: string = "110px";
  smallColumnLableWidth: string = "100px";
  titleColumnLableWidth: string = "180px";

  listLoading: boolean = false;
  list: ICouponModel[] = [];
  allList: ICouponModel[] = [];
  totalSize = 0;
  listQuery: ICouponListQuery = {
    type: "all",
    updatedTime: "",
  };
  maxPresentationPages: Number = 6;

  @Watch("panelObj", {
    deep: true,
    immediate: true,
  })
  _onPanelObjChange(value: IPanelData) {
    this.listQuery.type = value.type;
    if (value.type == "all") {
      this.list = this.allList;
    } else {
      let tempList: ICouponModel[] = [];
      this.allList.forEach((element: ICouponModel) => {
        if (
          (element.isPublished && value.type === "published") ||
          (!element.isPublished && value.type === "unpublished")
        ) {
          tempList.push(element);
        }
      });

      this.list = tempList;
    }
  }

  mounted() {
    this.refreshTable();
  }
  storeList: IStoreModel[] = [];
  public async refreshTable() {
    const res = await getAllStores();
    if (res && res.data && res.data.data) {
      const { data } = res.data;
      this.storeList = data;
    }
    this._fetchCoupons({
      ...this.listQuery,
    });
  }

  async _getMaxPresentationPages() {
    try {
      const res = await getWineryInfo();
      if (res && res.data && res.data.data) {
        const { maxPresentationPages } = res.data.data;
        this.maxPresentationPages = maxPresentationPages;
      }
    } catch {
      // console.log("_getMaxPresentationPages 出错了");
    }
  }

  async _fetchCoupons(params: ICouponListQuery) { 
    this.listLoading = true;
    try {
      const res = await getCoupon();
      if (res && res.data && res.data.data) {
        const { data } = res.data;
        let publishCount = 0;
        data.forEach((element: any) => {
          if (element.isPublished) {
            publishCount++;
          }
          element.redemptionCode= element.redemptionCodes[0]
          const selectPop = this.storeList.find((store) => {
            return element.storeIds[0] === store.id;
          }); 
          if (selectPop != null) {
            element.storeName = selectPop.name;
          }
          element.useTime =
            moment(element.validityTimeFrom).format("YYYY-MM-DD") +
            " 至 " +
            moment(element.validityTimeTo).format("YYYY-MM-DD");
        });

        CouponModule.SET_STORE_COUNET_RES({
          allCount:  data.length,
          publishedCount: publishCount,
          unpublishedCount: data.length - publishCount,
        });
        this.allList = this.list = data;
      } else {
        this.list = [];
      }
    } catch (error) {
      // console.log(error);
      // console.log("getWineryRecords 出错了");
    }
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  async _onClickEdit(item: ICouponModel) {
    this.$emit("onEditWinery", item);
  }

  _onChangeSwitch(value: boolean, data: ICouponModel, index: number) {
    if (data.isEverPublished&&value) {
      data.isPublished = false;
      this.$message.error("下架后不能重新上架。");
      return;
    }
    this.$confirm("", value ? "确认发布这个优惠卷吗？" : "确认取消发布吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(() => {
        this._onUpdatePublishStatus(value, data, index);
      })
      .catch(() => {
        data.isPublished = !data.isPublished;
      });
  }

  async _onUpdatePublishStatus(
    value: boolean,
    data: ICouponModel,
    index: number
  ) {
    try {
     const isPublished = value;
      data.isPublished = value;
      this.listLoading = true;
     const res = await publish(data.id, isPublished);
      this.listLoading = false;
      if (res.data.resultCode === 0) {
        this.refreshTable();
        this.$message.success("操作成功");
      }
    } catch {
      this.listLoading = false;
    }
  }

  // 删除
  _onClickDelete(id: string) {
    this.$confirm("", "确认删除这个酒庄介绍吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(() => {
        this._deleteCoupon(id);
      })
      .catch(() => {});
  }

  async _deleteCoupon(id: string) {
    try {
      this.listLoading = true;
      const res = await deleteCoupon(id);
      this.listLoading = false;
      if (res && res.status === 200) {
        this._fetchCoupons(this.listQuery);
        this.$message.success("操作成功");
      }
    } catch {
      this.listLoading = false;
    }
  }
  _showUsedCoupons(coupon: ICouponModel) {
    this.$emit("showUseCoupons", coupon);
  }

  _onCopy(item: ICouponModel) {
    const save = function (e: any) {
      e.clipboardData.setData("text/plain", item.title);
      e.preventDefault(); // 阻止默认行为
    };
    document.addEventListener("copy", save); // 添加一个copy事件
    document.execCommand("copy"); // 执行copy方法
    this.$message({ message: "复制成功", type: "success" });
  }
}
