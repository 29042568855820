


































import { Component, Vue } from "vue-property-decorator";
import PanelGroup from "@/components/PanelGroup.vue";
import CouponTable from "./components/CouponTable.vue";
import CouponEdit from "./components/CouponEdit.vue";
import { ITabModel, IPanelData } from "@/data/commonModel";
import { ICouponModel } from "./data/couponModel";
import { CouponModule } from "@/store/modules/coupon";
import   CustomTable from "./components/CustomTable.vue";
@Component({
  components: {
    PanelGroup,
    CouponTable,
    CouponEdit,
    CustomTable
  },
})
export default class extends Vue {
  editDialogVisible: boolean = false;
  editType: string = "add";
  panelObj: IPanelData = { type: "all", searchText: "", show: false };
  couponModel: ICouponModel | null = null;
 dialogTableVisible: boolean = false;
 coupon: ICouponModel  | null = null ;
 
  get tabs(): ITabModel[] {
    const arr = [
      {
        title: `全部(${CouponModule.storeCountRes.allCount})`,
        key: "all",
      },
      {
        title: `已发布(${CouponModule.storeCountRes.publishedCount})`,
        key: "published",
      },
      {
        title: `未发布(${CouponModule.storeCountRes.unpublishedCount})`,
        key: "unpublished",
      },
    ];
    return arr;
  }
  mounted() {}

  _handleSwitchTab(props: IPanelData) {
    this.panelObj = props;
  }

  _onClickAdd() {
    this.editType = "add";
    this.couponModel = null;
    this.editDialogVisible = true;
  }

  _handleEditWinerySuccess(type: string) {
    // WineryModule.FetchWineryCountByStatus();
    const ref: CouponTable = this.$refs["coupon-table"] as CouponTable;
    ref.refreshTable();
  }

  _handleEditWinery(value: ICouponModel) {
    this.editType = "edit";
    this.editDialogVisible = true;
    this.couponModel = JSON.parse(JSON.stringify(value));
  }
  showUseCoupons(coupon:ICouponModel){
this.dialogTableVisible=true
this.coupon=coupon
console.log(coupon)
  }
}
