





































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ImgUpload from "@components/ImgUpload.vue";
import { Form as ElForm } from "element-ui";
import { addCoupon, updateCoupon } from "@/remote/coupon";
import { ICouponModel } from "../data/couponModel";
import { IStoreModel } from "@/pages/store/data/storeModel";
import moment, { max } from "moment";
import { getAllPublishStores } from "@/remote/store";
// 装饰器模式
@Component({
  components: {
    ImgUpload,
    ElForm,
  },
})
export default class extends Vue {
  // 显示或隐藏
  @Prop({
    default: false,
  })
  dialogFormVisible!: boolean;
  // 编辑类型
  @Prop({
    default: "add",
  })
  editType!: string;
  @Prop() couponModel!: ICouponModel;
  couponTypes: any = [
    {
      value: "gift",
      label: "实体礼品",
    },
    {
      value: "discount",
      label: "虚拟优惠卷",
    },
  ];
  //快闪店
  storeList: IStoreModel[] = [];

  validatTime = (rule: any, value: any, callback: Function) => {
    if (value === "" || value === null) {
      callback(new Error("请输入有效期"));
    } else {
      callback();
    }
  };
  validatNumber = (rule: any, value: any, callback: Function) => {
    if (value <= 0) {
      callback(new Error("请输入发送数量"));
    } else {
      callback();
    }
  }; 
  rules = {
    type: [
      {
        required: true,
        message: "请输入礼劵类型",
        trigger: "blur",
      },
    ],
    validityTimeTo: [
      {
        required: true,
        message: "请输入有效期",
        trigger: "blur",
        validator: this.validatTime,
      },
    ],
    title: [
      {
        required: true,
        message: "请输入礼劵名称",
        trigger: "blur",
      },
    ],

    storeIds: [
      {
        required: true,
        message: "请选择关联门店",
        trigger: "blur",
      },
    ],
    totalQuantity: [
      {
        required: true,
        message: "请输入礼券总量",
        trigger: "blur",
        validator: this.validatNumber,
      },
    ],
  };
  // Data
  formLabelWidth: string = "140px";

  formParams: ICouponModel = {
    id: "",
    type: "",
    validityTimeFrom: "",
    validityTimeTo: "",
    title: "",
    description: "",
    termsAndConditions: "",
    storeIds: [],
    totalQuantity: 0,
    redemptionCode: "",
    isPublished: false,
    isEverPublished:false,
  };

  couponId: string = "";
  saveBtnLoading: boolean = false;

  //coupon规则
  item: string = "";
  couponRules: string[] = ["", "", "", "", ""];
  //有效期
  timeRanges: string[] = ["", ""];
  storeId:string=""
  unlimitPickerOptions = {};
  _onChangeTimeRange(event: any) {
    this.formParams.validityTimeFrom = moment(event[0]).format("YYYY-MM-DD");
    this.formParams.validityTimeTo = moment(event[1]).format("YYYY-MM-DD");
  }
  mounted() {
    this._fetchStores();
  }

  async _fetchStores() { 
    const res = await getAllPublishStores();
    if (res && res.data && res.data.data) {
      const { data } = res.data;
      this.storeList = data;
      if (this.formParams.storeIds[0]) {
        const selectPop = this.storeList.find((element) => {
          return element.id === this.formParams.storeIds[0];
        }); 
        if (selectPop != null) {
          this.storeId=selectPop.id
          
          const startDate = moment(selectPop!.openingTimeFrom).format(
            "YYYY-MM-DD"
          );
          const endDate = moment(selectPop!.openingTimeTo).format("YYYY-MM-DD");
          this.unlimitPickerOptions = {
            disabledDate: (time: Date) => {
              return (
                moment(time).isBefore(moment(startDate)) ||
                moment(time).isAfter(moment(endDate))
              );
            },
          };
        }
      }
    }
  }

  //选中某个快闪店
  selectPopStore(value: any) { 
    const selectPop = this.storeList.find((element) => {
      return element.id === value;
    });
    this.storeId = selectPop!.id;
    this.formParams.storeIds=[this.storeId]
    this.timeRanges = ["", ""]; 
    if (selectPop != null) {
      const startDate = moment(selectPop!.openingTimeFrom).format("YYYY-MM-DD");
      const endDate = moment(selectPop!.openingTimeTo).format("YYYY-MM-DD");
      this.unlimitPickerOptions = {
        disabledDate: (time: Date) => {
          return (
            moment(time).isBefore(moment(startDate)) ||
            moment(time).isAfter(moment(endDate))
          );
        },
      };
    }
  }

  // Watch
  @Watch("couponModel", {
    immediate: true,
    deep: true,
  })
  onCouponModelChange(value: ICouponModel) {
    if (!value) return; 
    this.couponId = value.id;
    this.formParams = value;
    if (value.termsAndConditions && value.termsAndConditions.length > 0) {
      value.termsAndConditions.forEach((element: string, index: number) => {
        this.couponRules[index] = element;
      });
    }
    if(value.storeIds&&value.storeIds.length>0)
    this.timeRanges = [
      this.formParams.validityTimeFrom,
      this.formParams.validityTimeTo,
    ];
  }

  // Methods
  _beforeModalClose() {
    this.$emit("update:dialogFormVisible", false); // 直接修改父组件的属性
  }

  _hide() {
    this.$emit("update:dialogFormVisible", false);
  }

  _onInput(value: any, type: string) {
    //  console.log(type);
    // console.log(value);
    // var value = event.target.value.trimStart();
    var temp = 0;
    var maxLen = type === "name" ? 20 : 16;
    if (type === "redemptionCode") {
      maxLen = 4;
    } else if (value.length === 0 && type === "subTitle") {
      // this.subTitleLength = 0;
    }

    for (var i = 0; i < value.length; i++) {
      if (/[\u4e00-\u9fa5]/.test(value[i])) {
        temp += 2;
      } else {
        temp++;
      }
      if (temp > maxLen) {
        // @ts-ignore
        value = value.substr(0, i).trimStart();
      }
    }
    if (type === "redemptionCode") {
      this.formParams.redemptionCode = value;
      // this.titleLength = temp > maxLen ? maxLen : temp;
    } else {
      // this.formParams.subTitle = value;
      // this.subTitleLength = temp > maxLen ? maxLen : temp;
    }
  }

  _calcTextLength(value: string): number {
    let temp = 0;
    for (var i = 0; i < value.length; i++) {
      if (/[\u4e00-\u9fa5]/.test(value[i])) {
        temp += 2;
      } else {
        temp++;
      }
    }
    return temp;
  }

  _onClickConfirm() {
    const form = this.$refs["wineryForm"] as ElForm;
    form.validate((valid: Boolean) => {
      if (valid) {
        this._showConfirmToast();
      } else {
        return false;
      }
    });
  }

  _showConfirmToast() {
    this.$confirm(
      this.editType === "edit"
        ? "确认更新该优惠卷吗？"
        : "确认保存为新的优惠卷吗？",
      "提示",
      {
        confirmButtonText: this.editType === "edit" ? "更新" : "保存",
        cancelButtonText: "取消",
        center: true,
      }
    )
      .then(() => {
        this._handleFormData();
      })
      .catch(() => {});
  }

  _onClickCancel() {
    this.$confirm("", "确认放弃编辑吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(() => {
        this._hide();
      })
      .catch(() => {});
  }

  // 处理表单数据
  _handleFormData() {
    this.formParams.termsAndConditions = [];
    this.couponRules.forEach((element) => {
      if (element && element.length > 0) {
        this.formParams.termsAndConditions.push(element);
      }
    }); 
    this.formParams.totalQuantity = Number.parseInt(
      this.formParams.totalQuantity + ""
    );
    this.formParams.storeIds=[this.storeId]
    this.saveBtnLoading = true;
      const {redemptionCode,...rest}=this.formParams
      let reqData: any =rest; 
    if (this.editType === "edit") {
      this._updateCoupon(reqData);
    } else {
      this._createCoupon(reqData);
    }
  }

  // 新增
  async _createCoupon(reqModel: ICouponModel) {
    try {
      const res = await addCoupon(reqModel);
      if (res.status === 200) {
        this.$message.success("保存成功");
        this.$emit("onEditWinerySuccess", this.editType);
        this._hide();
      }
      this.saveBtnLoading = false;
    } catch {
      this.saveBtnLoading = false;
      // console.log("_createWinery出错了");
    }
  }

  // 编辑
  async _updateCoupon(reqModel: ICouponModel) {
    try {
      const res = await updateCoupon(this.couponId, reqModel);
      if (res.status === 200) {
        this.$message.success("保存成功");
        this.$emit("onEditWinerySuccess", this.editType);
        this._hide();
      }
      this.saveBtnLoading = false;
    } catch {
      this.saveBtnLoading = false;
      // console.log("_updateWinery 出错了");
    }
  }
}
