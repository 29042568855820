




















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { userCoupons, reportCoupons, getCoupon } from "@/remote/coupon";
import { getAllStores } from "@/remote/store";
import Pagination from "@/components/pagination/Pagination.vue";
import {
  IStoreCouponListQuery,
  IStoreModel,
} from "@/pages/store/data/storeModel";
import moment, { max } from "moment";
import {
  ICouponModel,
  ICouponUsedListQuery,
} from "@/pages/coupon/data/couponModel";

// 装饰器模式
@Component({
  components: {
    Pagination,
  },
})
export default class extends Vue {
  // Props
  // 显示或隐藏
  @Prop({ default: false })
  dialogTableVisible!: boolean;

  @Prop() coupon!: ICouponModel;
redeemedCount:number=0
  list: IStoreModel[] = [];
  totalSize = 20;
  listQuery: ICouponUsedListQuery = {
    page: 1,
    size: 10,
  };
  @Watch("dialogTableVisible", { immediate: true, deep: true })
  onTimeVisibleChange() {
    if (this.dialogTableVisible) this.refreshTable();
  }
  _updatePage(value: any) {
    this.listQuery.page = value.page;
    this.refreshTable();
  }
  async refreshTable() { 
    let res = await getAllStores();
    let allStores: any = [];
    if (res?.data?.data) {
      allStores = res.data.data;
    }
    let allCoupons: any = [];
    res = await getCoupon();
    if (res?.data?.data) {
      allCoupons = res.data.data;
    }
    res = await userCoupons(this.coupon.id, this.listQuery);
    if (res && res.data && res.data.data) {
      var { total, items,redeemedCount } = res.data.data;
      this.redeemedCount=redeemedCount
      items.forEach((element: any) => {
        const filterCoupons: any = allCoupons.filter((coupon: any) => {
          return coupon.id === element.couponId;
        }); 
        if (filterCoupons && filterCoupons.length > 0) {
          element.title = filterCoupons[0].title;
          const filterStore: any = allStores.filter((store: any) => {
            return store.id === filterCoupons[0].storeIds[0];
          });
            
          if (filterStore && filterStore.length > 0) {
            element.storeName = filterStore[0].name;
          }
        }
        if(element.status === 'redeemed'){
          element.redeemedTime = moment(element.redeemedAt).format("YYYY-MM-DD  HH:mm:ss");
        }

        element.getTime = moment(element.createdAt).format("YYYY-MM-DD  HH:mm:ss");
      });
      this.list = items;
      this.totalSize = total;
    }
  }

  // Methods
  _beforeModalClose() {
    this.$emit("update:dialogTableVisible", false); // 直接修改父组件的属性
  }
  exportButtonLoading: boolean = false;
  async _onClickExport() {
    this.exportButtonLoading = true;
    try {
      const res = await reportCoupons(this.coupon.id);
      if (!res || !res.data) return;
      let a = document.createElement("a");
      let blob = new Blob([res.data], {
        type: "application/octet-stream", //这里放文件类型，后台返回的response会有这个文件类型，放进来就行
      });
      a.href = window.URL.createObjectURL(blob);
      a.download = "优惠券领取名单.xlsx";
      a.click();
      this.exportButtonLoading = false;
    } catch {
      this.exportButtonLoading = false;
      this.$message.error("导出失败，请重试");
      // console.log("exportCustomList 出错了");
    }
  }
}
