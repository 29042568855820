import http from './interface'
import { UserModule } from '@/store/modules/user'
import { ICouponModel,ICouponUsedListQuery } from '@/pages/coupon/data/couponModel'

// 搜索兑换码
export const searchRedeemCode = (code: string) => {
  return http.post(`/api/v3/coupons/search`, { code })
}

// 核销兑换码
export const applyRedeemCode = (code: string) => {
  return http.post(`/api/v3/coupons/${code}/apply`)
}
 

export const getCouponCountByStatus = () => {
  return http.get(`/api/v3/coupons/count`)
} 

export const getCoupon = ()=> { 
  return http.get('/api/v3/coupons')
}

export const addCoupon = (data:ICouponModel)=> { 
  return http.post('/api/v3/coupons',data)
}

export const deleteCoupon = (couponId: string) => {
  return http.delete(`/api/v3/coupons/${couponId}`)
}
export const updateCoupon = (couponId: string,data:ICouponModel) => {
  return http.patch(`/api/v3/coupons/${couponId}`,data)
}
export const userCoupons = (couponId: string,query:ICouponUsedListQuery) => {
  return http.get(`/api/v3/coupons/${couponId}/user-coupons`)
}
export const reportCoupons = (couponId: string) => {
  return http.get(`/api/v3/coupons/${couponId}/user-coupons/report`,{
    responseType: "blob", //告诉服务器我们需要的响应格式
  })
}

export const publish=(couponId:string,isPublished:boolean)=>{
  return http.post(`/api/v3/coupons/${couponId}/publish`,{isPublished})
}